import PremiumLabelRoommate from "@/components/Common/Labels/PremiumLabelRoommate/PremiumLabelRoommate";
import LabelFree from "@/components/Common/Labels/LabelFree/LabelFree";
import CoinsIcon from "@/components/Common/Icons/CoinsIcon";

export default {
    name: 'PremiumAdSelection',
    props: {
        adtype: {
            type: String,
            default: ''
        },
        days: {
            type: String,
            default: ''
        },
        credits: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        amount: {
            type: String,
            default: ''
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PremiumLabelRoommate,
        LabelFree,
        CoinsIcon
    },
    methods: {
        onChange(event) {
            event.target.checked = this.$props.selected;
        }
    }
}