export default {
    name: 'CreditWallet',
    data() {
        return {
            available: null,
            promoAmount: 10,
            promoDiscount: 10,
            regularPrice: 20,
            discountedPrice: 16,
        }
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
    },
}